html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.globalSpin {
  width: 100%;
  margin: 40px 0 !important;
}
ul,
ol {
  list-style: none;
}
.textCenter {
  text-align: center !important;
}
.mt-xs {
  margin-top: 5px;
}
